var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          disabled: _vm.computed,
          type: "number",
          "persistent-hint": "",
          hint: _vm.hint,
          "hide-spin-buttons": "",
          dense: "",
          clearable: "",
          rules: [(value) => _vm.positiveValueRules(value)],
        },
        on: {
          input: _vm.quantityChanged,
          "click:clear": function ($event) {
            return _vm.clearQuantity()
          },
        },
        model: {
          value: _vm.validatedcapacity,
          callback: function ($$v) {
            _vm.validatedcapacity = $$v
          },
          expression: "validatedcapacity",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }