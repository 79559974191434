/**
 * Un mixin outil pour la manipulation de l'alert dialog quand les datas n'ont pas été sauvegardées
 * 
     <AlertNotSavedModifsComponent
    :show = "showAlertQuit"
    @quit = "onQuitAlert"
    @notquit = "onNotQuitAlert"
    />
 */
export let AlertNotSavedModifsMixin = {
  data() {
    return {
      showAlertQuit: false,
      nextAlertQuit: null,
      enabledAlertQuit: true, // Etat d'activation de l'alerte
    };
  },
  /** Méthode associée à lévènement "retour" du router  
  * Permet de mapper le bouton retour perso, le bouton retour navigateur, un bouton prévious de sourie.
  */
  beforeRouteLeave(to, from, next) {
    if (!this.enabledAlertQuit) {
      next();
      return;
    }
    if (!this.hasChanged) {      
      next();      
    } else {
      this.nextAlertQuit = next;
      this.showAlertQuit = true;
    }
  },
  methods: {
    onQuitAlert() {
      this.showAlertQuit = false;
      this.nextAlertQuit();
    },
    onNotQuitAlert() {
      this.showAlertQuit = false;
      this.nextAlertQuit(false);
    },
    disableAlertQuit() {
      this.enabledAlertQuit = false;
    }

  },
  mounted() {},
};

export default AlertNotSavedModifsMixin;
