<template>
  <div v-if="tree">
    <v-treeview
      expand-icon="mdi-none"
      open-all
      dense
      :items="tree.children"
      :open.sync="openNodes"
    >
      <template v-slot:prepend="{}">
        <v-icon color="primary">
          {{ "mdi-minus" }}
        </v-icon>
      </template>

      <template v-slot:label="{ item }">
        <div>
          <div v-if="item.header" class="text-uppercase text-h6">
            {{ item.name }}
          </div>
          <v-container class="pa-md-0" v-if="!item.header">
            <div :class="item.parent ? `subtitle-1` : ``" center>
              {{ item.name }}
            </div>
            <v-divider />
          </v-container>
        </div>
      </template>

      <template v-slot:append="{ item }">
        <div v-if="!item.header">
          <div v-if="!active" :class="item.parent ? `subtitle-1` : ``">
            <div v-if="item.computed">{{ item.quantity }} *</div>
            <div v-else>{{ item.quantity }}</div>
          </div>

          <div v-else>
            <CapacityNodeComponent
              :active="active"
              v-model="item.quantity"
              :computed="item.computed"
              :integrityerror="item.integrityerror"
              :integritywarning="item.integritywarning"
              @quantityChanged="(newval) => onValueChanged()"
              @valueClear="onClear()"
            />
          </div>
        </div>
      </template>
    </v-treeview>
    
    <v-row v-if="!active" class="mt-2" justify="end">* quantités calculées</v-row>
  </div>
</template>
<style></style>
<script>
import * as logger from "@/tools/logger.js";


class TreeModelBusiness {
  constructor(parent) {
    this.parent = parent;
  }

  loggerNode = function (node) {
    logger.debug(node.id);
  };
  loggerParent = function (parentNode, node) {
    logger.debug(parentNode.id + " -> " + node);
  };

  logger = function (node) {
    logger.debug(node.id);
  };

  resetWarningsAndErrors(model) {
    this.actionOnAllNode(model, (node) => {
      node.integrityerror = false;
      node.integritywarning = false;
    });
  }

  actionOnParentNodeById(node, id, action) {
    if (node.children) {
      for (let subnode of node.children) {
        if (subnode.id === id) {
          if (action(node, subnode)) {
            return true;
          }
        }
        if (this.actionOnParentNodeById(subnode, id, action)) return true;
      }
    }
    return false;
  }

  actionOnAllNode(node, action) {
    action(node);
    if (node.children) {
      node.children.forEach((subnode) => {
        this.actionOnAllNode(subnode, action);
      });
    }
  }

  logTree(node, level) {
    let str = "";
    for (let i = 0; i < level; i++) str += " |";
    logger.debug(
      str +
        "-> [" +
        node.id +
        ":" +
        node.name +
        "] -> " +
        node.quantity +
        "(" +
        node.theoricalquantity +
        ")"
    );
    if (node.children) {
      node.children.forEach((subnode) => {
        this.logTree(subnode, level + 1);
      });
    }
  }

  updateTheoricalQuantityAndComputed(node) {
    let log = false;
    if (!node.children || node.children.length == 0) {
      node.theoricalquantity = node.quantity;
      if (log)
        logger.debug(
          node.id + ": Feuille, donc theoriquement: " + node.theoricalquantity
        );
      node.computed = false;
      return node.theoricalquantity;
    } else {
      let count = null;
      if (node.children) {
        for (let subnode of node.children) {
          let subcount = this.updateTheoricalQuantityAndComputed(subnode);
          if (log)
            logger.debug(
              node.id +
                ": Noeud. La somme des fils de  " +
                subnode.id +
                " = " +
                subcount +
                " total: " +
                count
            );
          if (subcount) {
            if (!count) count = subcount;
            else count = Number(count) + Number(subcount);
          }
        }
        if (count) {
          node.computed = true;
          node.quantity = count;
          node.theoricalquantity = count;
          if (log) logger.debug(node.id + ": donc theoriquement: " + count);
          return count;
        } else {
          node.computed = false;
          node.theoricalquantity = node.quantity;
          if (log)
            logger.debug(
              node.id +
                ": Noeud avec uniquement des feuilles vides: " +
                node.theoricalquantity
            );
          return node.theoricalquantity;
        }
      }
    }
  }

  updateWarningAndErrorAboutNewNodeValue(tree, nodeid, newvalue) {
    let node = this.findNodeForId(tree, nodeid);
    if (node) {
      this.checkQuantityForChilds(node, newvalue);
    }
  }

  checkQuantityForChilds(node, newvalue) {
    let count = null;
    if (node.children) {
      for (let subnode of node.children) {
        let subcount = subnode.quantity;
        if (subcount) {
          if (!count) count = subcount;
          else count = Number(count) + Number(subcount);
        }
      }
      if (newvalue != count) {
        for (let subnode of node.children) {
          subnode.integrityerror =
            "devrait etre un sous ensemble de " + newvalue;
        }
      } else {
        for (let subnode of node.children) {
          subnode.integrityerror = "";
        }
      }
    }
  }

  findNodeForId(node, id) {
    if (node.id === id) return node;
    if (node.children) {
      for (let subnode of node.children) {
        let val = this.findNodeForId(subnode, id);
        if (val) {
          return val;
        }
      }
    }
    return null;
  }

  getAllNodesIds(tree) {
    let resultids = [];
    this.actionOnAllNode(tree, (node) => {resultids.push(node.id);});
    return resultids;
  }
}
//http://localhost:8080/capacite/etablissement/editer/7
import CapacityNodeComponent from "@/components/ui/TreeViewCapacity/CapacityNodeComponent.vue";

export default {
  name: "TreeViewCapacityComponent",
  components: { CapacityNodeComponent },
  props: {
    treeNodeFactory: null,
    tree: null,
    active: { type: Boolean, default: true },
  },
  data() {
    return {
      treeBusiness: TreeModelBusiness,
      originalQuantitiesValues: {},
      // Le nouvel élément ajouté. Permet de vider la valeur du textfield après son ajout
      newChild: undefined,
      // La liste des noeud ouverts
      openNodes: [],
    };
  },
  watch: {
    tree() {
      let allnodesids = this.treeBusiness.getAllNodesIds(this.tree);
      this.openNodes = allnodesids;
    }
  },
  methods: {
    onValueChanged() {
      this.treeBusiness.updateTheoricalQuantityAndComputed(this.tree);
    },
    onClear() {
      this.treeBusiness.updateTheoricalQuantityAndComputed(this.tree);
    },
  },
  mounted() {
    this.treeBusiness = new TreeModelBusiness(this);
  },
};
</script>
