var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tree
    ? _c(
        "div",
        [
          _c("v-treeview", {
            attrs: {
              "expand-icon": "mdi-none",
              "open-all": "",
              dense: "",
              items: _vm.tree.children,
              open: _vm.openNodes,
            },
            on: {
              "update:open": function ($event) {
                _vm.openNodes = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend",
                  fn: function ({}) {
                    return [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v(" " + _vm._s("mdi-minus") + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "label",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        [
                          item.header
                            ? _c(
                                "div",
                                { staticClass: "text-uppercase text-h6" },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            : _vm._e(),
                          !item.header
                            ? _c(
                                "v-container",
                                { staticClass: "pa-md-0" },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: item.parent ? `subtitle-1` : ``,
                                      attrs: { center: "" },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  ),
                                  _c("v-divider"),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "append",
                  fn: function ({ item }) {
                    return [
                      !item.header
                        ? _c("div", [
                            !_vm.active
                              ? _c(
                                  "div",
                                  { class: item.parent ? `subtitle-1` : `` },
                                  [
                                    item.computed
                                      ? _c("div", [
                                          _vm._v(_vm._s(item.quantity) + " *"),
                                        ])
                                      : _c("div", [
                                          _vm._v(_vm._s(item.quantity)),
                                        ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("CapacityNodeComponent", {
                                      attrs: {
                                        active: _vm.active,
                                        computed: item.computed,
                                        integrityerror: item.integrityerror,
                                        integritywarning: item.integritywarning,
                                      },
                                      on: {
                                        quantityChanged: (newval) =>
                                          _vm.onValueChanged(),
                                        valueClear: function ($event) {
                                          return _vm.onClear()
                                        },
                                      },
                                      model: {
                                        value: item.quantity,
                                        callback: function ($$v) {
                                          _vm.$set(item, "quantity", $$v)
                                        },
                                        expression: "item.quantity",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              405290823
            ),
          }),
          !_vm.active
            ? _c("v-row", { staticClass: "mt-2", attrs: { justify: "end" } }, [
                _vm._v("* quantités calculées"),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }