import ApiService from "../api_service";
import * as statusMapper from "@/service/error_request_mapper.js";

export class CapacitiesService extends ApiService {
  constructor(api) {
    super(api);
  }

  /**
   * Retourne la liste des templates de capacités pour une activité donnée
   * @param {*} activityId
   */
  async getCapacityTemplatesByActivity(activityId) {
    return this.api
      .getCapacityTemplatesByActivity(activityId)
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(activityId);
        converter.convert(error);
      });
  }

  async getCapacityQuantities(capacityId) {
    return this.api
      .getCapacityQuantities(capacityId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(capacityId);
        converter.convert(error);
      });
  }

  async getEstablishementActivityCapacities(establisjmentId) {
    return this.api
      .getEstablishementActivityCapacities(establisjmentId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(establisjmentId);
        converter.convert(error);
      });
  }

  async updateEstablishementActivityCapacities(establishmentId, capacities) {
    return this.api
      .updateEstablishmentCapacities(establishmentId, capacities)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(establishmentId);
        converter.convert(error);
      });
  }

  
}
