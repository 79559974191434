export class TreeNodeFactory {
  
    constructor() {
      this.currentindex = 0;
    }
    createNewNodeFromDTO(dto) {
      return  {id:++this.currentindex,
              name: dto.label,
              quantity: dto.quantity,
              theoricalquantity: dto.quantity,
              computed: false,
              active: true,
              payload: {capacityId:dto.capacityId, label:dto.label, quantity:dto.quantity},
              integrityerror: null,
              integritywarning: null,
              editable: {type: Boolean, default: true},
              parent:false,
              children: []};
    }
    createNewEmptyNode() {
      return  {id:++this.currentindex,
              name: '',
              computed: false,
              active: false,
              header: true,
              payload: {},
              children: []};
    }
  }
  
  export class TreeModelConverter {
  
    constructor(treeNodeFactory) {
        this.treeNodeFactory = treeNodeFactory;
      }
  
    convertDtoToTreeModel(treedto, activities) {
      let root = this.treeNodeFactory.createNewNodeFromDTO({name:"root"})

      let group = [];
      treedto.capacities.forEach((tree) => {
        const { activityId } = tree;
        let currentActivity = group.find((activity) => activity.id === activityId);
        if(!currentActivity) {
          let foundActivityName = activities.find((activity) => activity.id === activityId).digitalName;
          currentActivity = {id: activityId, activityname: foundActivityName, capacities:[]};
          group.push(currentActivity);
        }
        currentActivity.capacities.push(tree);
      });
      group.sort((a, b) => (a.activityname > b.activityname) ? 1 : -1);
      //Premier etage, c'est les activités
      group.forEach((activity) => {
        let rootnode = this.treeNodeFactory.createNewEmptyNode();
        rootnode.name =  activity.activityname;
        rootnode.payload = { ...rootnode.payload, activityId: activity.id};
        activity.capacities.forEach((subnode) => {
          let treemodel = this.buildDtoToTreeModel(subnode);
          // 1er élément parent
          treemodel.parent = true;
          rootnode.children.push(treemodel);
        });
        rootnode.children.sort((a, b) => (a.name > b.name ? 1 : -1));
        root.children.push(rootnode);
      });
      return root;
    }
  
    buildDtoToTreeModel(nodedto)  {
      let newnode = this.treeNodeFactory.createNewNodeFromDTO(nodedto);
      if(nodedto.subCapacities) {
        nodedto.subCapacities.forEach((subnodedto) => {
          newnode.children.push(this.buildDtoToTreeModel(subnodedto));
          if(subnodedto.quantity) newnode.computed = true;
        })
        newnode.children.sort((a, b) => (a.name > b.name) ? 1 : -1);
      }
      return newnode;
    }
  
    convertTreeModelToDto(treemodel, establishmentId) {
      let dto = {capacities: []};
      
      treemodel.children.forEach((activity) => {
        activity.children.forEach((capacity) => {
          let activityrootnode = this.buildTreeModelToDto(capacity);
          let { activityId } = activity.payload;
          activityrootnode.activityId = activityId;
          dto.capacities.push(activityrootnode);
        });
      });
      dto.establishmentId = establishmentId;
      return dto;
    }
  
    buildTreeModelToDto(nodetreemodel)  {
      let newnodedto = { capacityId: nodetreemodel.payload.capacityId, label: nodetreemodel.payload.label, quantity: nodetreemodel.quantity, subCapacities:[]};
      if(nodetreemodel.children) {
        nodetreemodel.children.forEach((subnodedto) => {
          newnodedto.subCapacities.push(this.buildTreeModelToDto(subnodedto));
        })
      }
      return newnodedto;
    }
  }
  

export default TreeNodeFactory;
  
