<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>
      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent
              title="Edition des capacités pour un établissement"
            />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between" v-if="!loading">
                <div>Choix d'un établissement</div>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col  align-self="center">
                  <v-autocomplete
                    v-model="establishment"
                    :items="establishments"
                    :filter="customFilter"
                    :search-input.sync="search"
                    @input="selectEstablishment"
                    item-text="digitalName"
                    return-object
                    placeholder="Choisir un établissement"
                    clearable
                    no-data-text="aucun établissement"
                    class="ma-0 pa-0"
                  >
                  <template slot="item" slot-scope="{item}">
                    <v-col class="py-1 ma-0">
                      <div>
                        <span v-for="(it, index) in  formatMatchingString(item.digitalName, search)" :key="index" :class="it.highlight ? 'primary--text' : ''" v-html="formatSpaceInHtmlCompliente(it.text)" ></span> 
                      </div>
                        <v-row no-gutters
                        v-for="(it, index) in  getMatchingFilterOItem(item, search)" :key="index" 
                        class="font-italic text-caption font-weight-light ml-4">
                          <span 
                          v-for="(token, idx2) in  formatMatchingString(it, search)" :key="idx2" 
                          :class="token.highlight ? 'primary--text' : ''" v-html="formatSpaceInHtmlCompliente(token.text)"
                          ></span> 
                        </v-row>
                      
                    </v-col>
                  </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- Treeview -->
            <div v-if="showCapacities">
              <v-card-title class="font-weight-regular" >
                <v-row
                align="center"
                no-gutters
                justify="space-between"
                v-if="!loading && establishment"
                >
                  <!-- Titre des capacités -->
                  <v-col cols="8">
                    <v-row no-gutters>
                      <div>Capacités de l'établissement </div>
                      <div class="font-weight-bold ml-1">{{establishment.digitalName}}</div>
                    </v-row>
                  </v-col>

                  <v-col cols="4"
                  align="right" 
                  v-if="showButtonModif">
                    <v-btn
                    v-if="!editTree"
                    class="ma-2 px-4 btn"
                    @click="onEditTree()"
                    color="primary"
                    :disabled="!establishment || editTree"
                    outlined
                    >
                      Mettre à jour les quantités
                      <!-- <v-icon>mdi-pencil</v-icon> -->
                    </v-btn>

                    <v-btn
                    v-else
                    outlined
                    @click="onQuitEditTree()"
                    color="primary"
                    class="ma-2 px-4 btn"
                    >
                      Quitter le mode d'édition
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text v-if="establishment">
                <v-row no-gutters align="center">
                  <v-col cols="12" align-self="center">
                    <TreeViewCapacityComponent
                      :tree="treemodel"
                      :active="editTree"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions v-if="editTree">
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  class="ma-2 px-4 btn"
                  color="primary"
                  :disabled="!canSave"
                  @click="save"
                  >Enregistrer</v-btn
                >
              </v-card-actions>
            </div>

            <!-- Affichage d'un message de non présence de capacités -->
            <div v-if="showMessageNoQuantities">
              <v-card-title class="font-weight-bold" >
                Aucune capacité de définie pour la ou les activités de cet établissement.
              </v-card-title>
            </div>

          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

    <AlertNotSavedModifsComponent
    :show="showAlertQuit"
    @quit="onQuitAlert"
    @notquit="onNotQuitAlert"
    />
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { CapacitiesService } from "@/service/etablishment/capacities_service.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import TreeViewCapacityComponent from "@/components/ui/TreeViewCapacity/TreeViewCapacityComponent.vue";
import {
  TreeNodeFactory,
  TreeModelConverter,
} from "@/components/ui/TreeViewCapacity/CapacityTreeService.js";
import * as exceptions from "@/service/exception_to_message.js";

import { hasRoles } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";
import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

//import * as logger from "@/tools/logger.js";
import { isNullOrEmpty } from "@/tools/string_tool.js";


export default {
  name: "EditEstablishmentCapacities",
  components: { 
    Workflow, 
    TitleAndReturnComponent, 
    TreeViewCapacityComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [
    WorkflowMixin, 
    RoleMixin, 
    TableViewEditWorkflowMixin, 
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      treeNodeFactory: TreeNodeFactory,
      treeModelConverter: TreeModelConverter,
      /**en cours de chargement. */
      loading: false,

      editTree: false,

      /**le service d'accès au APIs. */
      service: null,

      capacities: null,
      treemodel: null,
      initialquantities: [],

      /** La liste des établissement*/
      establishments: [],
      /** l'établissement choisi */
      establishment: null,

      serviceEstablishment: null,
      capacitiesService: null,
      activitiesService: null,

      search: "",
      /** Définition du rôle nécessaire pour l'édition de la vue */
      roleForEdition: [RolesApplicationEnum.EditCapacitiesEstablishment],
      // Boolean qui permet le mode édition
      canEditRole: null,
    };
  },
  methods: {
    customFilter(item, queryText, itemText) {
      let queryTextupperCase = queryText.toUpperCase();
      if(itemText.toUpperCase().includes(queryTextupperCase)) return true;
      let found = item.fields.find((field) => field && field.toUpperCase().includes(queryTextupperCase));
      if(found) {
        return true;
      }
      return false;
    },
    getMatchingFilterOItem(item, queryText) {
      let queryTextupperCase = !queryText || queryText.toUpperCase();
      return item.fields.filter((field) => field && field.toUpperCase().includes(queryTextupperCase));
    },

    formatSpaceInHtmlCompliente(text) {
      return text.replaceAll(' ', '&nbsp;');
    }, 

    formatMatchingString(text, search) {
      let tokens = [];
      this.searchForMatchingTokens(text, search, tokens); 
      return tokens; 
    },
    searchForMatchingTokens(text, search, tokens) {
      if(isNullOrEmpty(search) || isNullOrEmpty(text)) {
        tokens.push({text: text, highlight: false});
      }
      else { 
        let indexstart = text.toUpperCase().indexOf(search.toUpperCase());
        if(indexstart > -1) {
          tokens.push({text: text.substring(0, indexstart), highlight: false});
          tokens.push({text: text.substring(indexstart, indexstart + search.length), highlight: true});
          this.searchForMatchingTokens(text.substring(indexstart + search.length, text.length), search, tokens);
        }
        else {
          tokens.push({text: text, highlight: false});
        }
      }
    },

    /** Sélectionne et lance la recherche du praticien */
    async selectEstablishment(value) {
      if (!value) {
        this.establishment = null;
        return;
      }
      // Vérifie si présence de changement
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function(valuePopup) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (valuePopup == undefined) {
            this.editTree = false;
            this.loadTreeDatas(value);
          }
        };
      } else {
        this.loadTreeDatas(value);
      }
    },

    /** Charge les données de capacités de l'établissement */
    async loadTreeDatas(value) {
      try {
        if (!value) {
          this.establishment = null;
          return;
        }
        // Récupération des capacités
        this.capacities =
          await this.capacitiesService.getEstablishementActivityCapacities(
            this.establishment.id
          );
        // Convertion du modèle api en modèle graphique
        this.treemodel = this.treeModelConverter.convertDtoToTreeModel(
          this.capacities,
          await this.activitiesService.getActivities()
        );
        // Initialisation des données de quantités de l'arbre
        this.initialquantities = this.getQuantitiesMap(this.treemodel);

      } catch (error) {
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },    

    /** Activation du mode édition */
    onEditTree() {
      this.editTree = true;
    },

    /**
     * Méthode pour sortie du mode édition 
     * Vérifie si des changements sont en cours */
    onQuitEditTree() {
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function(value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.editTree = false;
            this.loadTreeDatas(this.establishment);
          }
        };
      } else {
        this.editTree = false;
      }
    },
    async load() {
      try {
        this.loading = true;
        
        let detailsestab = await this.serviceEstablishment.getAllEstablishmentActivityDetails();
        this.establishments = [];
        detailsestab.forEach((activitydetail) =>{
          let estab = {id:activitydetail.establishmentId, digitalName:activitydetail.digitalname, fields: [] };
          for(let activity of activitydetail.details) {
            if(!isNullOrEmpty(activity.commercialName))  {
              if(!estab.fields.find((field) => field == activity.commercialName))
                estab.fields.push(activity.commercialName);
            }
          }
          this.establishments.push(estab);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique" + error)
        );
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        let topersist = this.treeModelConverter.convertTreeModelToDto(
          this.treemodel,
          this.establishment.id
        );
        await this.capacitiesService.updateEstablishementActivityCapacities(
          this.establishment.id,
          topersist
        );
        this.initialquantities = this.getQuantitiesMap(this.treemodel);
        // on re-bascule en mode visualisation
        this.editTree = false;
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },
    getQuantitiesMap(tree) {
      let quantitiesMap = [];
      this.getQuantitiesMapRecc(tree, quantitiesMap);
      return quantitiesMap;
    },
    getQuantitiesMapRecc(node, quantitiesMap) {
      if (node && (node.payload) && (node.payload.capacityId))
        quantitiesMap.push({
          id: node.payload.capacityId,
          quantity: node.quantity || null,
        });
      if (node && node.children)
        node.children.forEach((subnode) =>
          this.getQuantitiesMapRecc(subnode, quantitiesMap)
        );
    },
  },
  computed: {
    canSave() {
      let newquantities = this.getQuantitiesMap(this.treemodel);
      for (let quantity of this.initialquantities) {
        let found = newquantities.find(
          (item) =>
            item.id === quantity.id && item.quantity === quantity.quantity
        );
        if (!found) {
          return true;
        }
      }
      return false;
    },

    /** Retourne l'état d'affichage des quantités de capacités */
    showCapacities() {
      let show = true;

      // Etablissement non sélectionné, on affiche pas
      if (!this.establishment) {
        show = false;
      }
      // Pas de capacité, on affiche pas
      if (!this.capacities || this.capacities.capacities.length == 0) {
        show = false;
      }

      return show;
    },

    /** Retourne l'état d'affichage du message de non présence de capacités */
    showMessageNoQuantities() {
      let show = true;

      // Etablissement non sélectionné, on affiche pas
      if (!this.establishment) {
        show = false;
      }
      // Pas de capacités
      if (!this.capacities || this.capacities.capacities.length > 0) {
        show = false;
      }

      return show;
    },

    // retourne s'il y a eu des changements dans les saisie de l'arbre
    hasChanged() {
      let changed = false;

      if (this.canSave) {
        changed = true;
      }

      return changed;
    },

    /** Retourne la visibilité du bouton d'édition des quantités */
    showButtonModif() {
      let view = true;

      // n'apparait pas s'il l'on as pas mes droits
      if (!this.canEditRole) {
        view = false;
      }
      return view;
    },

    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;
      return changed;
    },
  },
  mounted() {
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.activitiesService = new ActivityService(this.$api);
    this.capacitiesService = new CapacitiesService(
      this.$api.getCapacitiesApi()
    );

    // Configure les éléments en fonction des rôles
    this.canEditRole = hasRoles(this.roleForEdition);

    this.treeNodeFactory = new TreeNodeFactory();
    this.treeModelConverter = new TreeModelConverter(this.treeNodeFactory);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    
    this.load();
  },
};
</script>

<style>
  .truncate {
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
</style>
