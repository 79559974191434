var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mb-2", attrs: { justify: "center", "no-gutters": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "480" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "mb-4" }, [
                _vm._v(" Travail non enregistré "),
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "Vos modifications seront perdues si vous ne les enregistrez pas.."
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-4 btn",
                      attrs: { color: "primary", outlined: "" },
                      on: { click: _vm.onButtonYes },
                    },
                    [
                      _c("div", { staticClass: "capitalize" }, [
                        _vm._v(" ne pas enregistrer "),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-4 btn",
                      attrs: { color: "primary", outlined: "" },
                      on: { click: _vm.onButtonNo },
                    },
                    [
                      _c("div", { staticClass: "capitalize" }, [
                        _vm._v(" annuler "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }