<template>
  <v-row justify="center" class="mb-2" no-gutters>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="480"
    >
      <v-card>
        <v-card-title class=" mb-4">
          Travail non enregistré
        </v-card-title>
        <v-card-text>
          <p>Vos modifications seront perdues si vous ne les enregistrez pas..</p>   
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="px-4 btn"
            color="primary"
            outlined
            @click="onButtonYes"
          >
            <div class="capitalize">
              ne pas enregistrer
            </div>
          </v-btn>
          <v-btn 
            class="px-4 btn"
            color="primary"
            outlined
            @click="onButtonNo"
          >
            <div class="capitalize">
                  annuler
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<script>
export default {
  name: "AlertNotSavedModifsComponent",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: this.show,
    };
  },
  methods: {
    /** Clic sur le bouton OUI */
    onButtonYes() {
      this.close();
      this.$emit("quit");
    },
    /** Clic sur le bouton NON */
    onButtonNo() {
      this.close();
      this.$emit("notquit");
    },
    /** Ferme la dialog */
    close() {
      this.showDialog = false;
    }
  },
  watch: {
    show(value) {
      this.showDialog = value;
    },
  },
  mixins:[],
  computed: {},
  mounted() {},
}
</script>