<template>
  <div>
    <v-text-field
      v-model="validatedcapacity"
      :disabled="computed"
      type="number"
      @input="quantityChanged"
      persistent-hint
      :hint="hint"
      hide-spin-buttons
      dense
      clearable
      @click:clear="clearQuantity()"
      :rules="[(value) => positiveValueRules(value)]"
    >
    </v-text-field>
  </div>
</template>
<style></style>
<script>
export default {
  name: "CapacityNodeComponent",
  props: {
    value: {},
    computed: {},
    integrityerror: null,
    integritywarning: null,
  },
  data() {
    return {
      validatedcapacity: this.value,
    };
  },
  watch: {
    value() {
      this.validatedcapacity = this.value;
    },
  },
  methods: {
    /** Restriction pour les nombres négatifs */
    positiveValueRules(value) {
      if (value) {
        if (parseFloat(value) < 0) {
          return "pas de valeur négative";
        }
      }
      return true;
    },
    quantityChanged(newvalue) {
      this.$emit("input", newvalue);
      if (!newvalue || newvalue === null) this.$emit("valueClear");
      else this.$emit("quantityChanged", newvalue);
    },
    clearQuantity() {
      this.validatedcapacity = null;
      this.$emit("input", this.validatedcapacity);
      this.$emit("valueClear");
    },
  },
  computed: {
    hint() {
      if (this.computed) return "Cette valeur est calculée";
      return this.integrityerror || this.integritywarning;
    },
    // canBeClear() {
    //   return ((!this.computed) && (this.validatedcapacity) && (this.validatedcapacity != null));
    // }
  },
};
</script>
